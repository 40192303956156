var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "12" } },
        [
          _c("cp-document-field", {
            ref: "document_field",
            attrs: {
              autofocus: "",
              "error-messages": _vm.getErrorMessages(_vm.fieldName),
              hint: _vm.$t(
                "components.inputs.SearchCustomerInput." +
                  (_vm.value.useCNPJ ? "cnpj" : "cpf") +
                  ".hint"
              ),
              label: _vm.$t(
                "components.inputs.SearchCustomerInput." +
                  (_vm.value.useCNPJ ? "cnpj" : "cpf") +
                  ".label"
              ),
              placeholder: _vm.$t(
                "components.inputs.SearchCustomerInput." +
                  (_vm.value.useCNPJ ? "cnpj" : "cpf") +
                  ".placeholder"
              ),
              "success-messages": !!_vm.selectedCustomer
                ? [_vm.$t("components.inputs.SearchCustomerInput.success")]
                : [],
              success: !!_vm.selectedCustomer,
              useCNPJ: _vm.value.useCNPJ,
              loading: _vm.loadingCustomer,
              disabled: _vm.loading,
              value: _vm.document,
              solo: ""
            },
            on: {
              input: function($event) {
                _vm.clearErrorMessages(_vm.fieldName)
                _vm.searchCustomerByDocument($event)
              },
              "update:useCNPJ": function($event) {
                return _vm.$set(_vm.value, "useCNPJ", $event)
              },
              "update:use-c-n-p-j": function($event) {
                return _vm.$set(_vm.value, "useCNPJ", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _vm.customerNotFound
            ? _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.inputs.SearchCustomerInput.not_found")
                    ) +
                    " "
                )
              ])
            : _vm.networkError
            ? _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(" " + _vm._s(_vm.$t("globals.network_error")) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }