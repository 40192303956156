<template>
    <v-row>
        <!-- Seleção de documento -->
        <v-col cols="12" class="pt-0">
            <cp-document-field
                autofocus
                ref="document_field"
                :error-messages="getErrorMessages(fieldName)"
                @input="clearErrorMessages(fieldName); searchCustomerByDocument($event)"
                :hint="$t(`components.inputs.SearchCustomerInput.${value.useCNPJ ? 'cnpj' : 'cpf'}.hint`)"
                :label="$t(`components.inputs.SearchCustomerInput.${value.useCNPJ ? 'cnpj' : 'cpf'}.label`)"
                :placeholder="$t(`components.inputs.SearchCustomerInput.${value.useCNPJ ? 'cnpj' : 'cpf'}.placeholder`)"
                :success-messages="!!selectedCustomer ? [$t('components.inputs.SearchCustomerInput.success')] : []"
                :success="!!selectedCustomer"
                :useCNPJ.sync="value.useCNPJ"
                :loading="loadingCustomer"
                :disabled="loading"
                :value="document"
                solo
            >
            </cp-document-field>
        </v-col>
        <!-- Mostra um card do cliente -->
        <v-col class="py-0">
            <v-alert v-if="customerNotFound" type="error">
                {{ $t('components.inputs.SearchCustomerInput.not_found') }}
            </v-alert>
            <v-alert v-else-if="networkError" type="error">
                {{ $t('globals.network_error') }}
            </v-alert>
        </v-col>
    </v-row>    
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import FormMixin from '@/mixins/FormMixin'
import BaseModel from '@/models/BaseModel'
import Customer from '@/models/Customer'

export default {
    name: 'SearchCustomerInput',
    mixins: [ FormMixin(BaseModel, 'document_field'), HasErrorHandlerMixin ],
    props: {
        document: {
            required: true,
        },
        value: {
            required: true,
        },
        fieldName: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loadingCustomer : false,
        selectedCustomer: null,
        customerNotFound: false,
        networkError    : false,
    }),    
    created() {
        if (Customer.determineUseCNPJ(this.document))
            this.value.useCNPJ = true
    },
    methods: {
        async searchCustomerByDocument(value) {
            this.selectedCustomer      = null
            this.customerNotFound      = false
            this.networkError          = false
            value                      = value ? value.replace(/\D/g, '') : ''
            this.value[this.fieldName] = value

            if (this.loadingCustomer || (this.value.errors[this.fieldName] && this.value.errors[this.fieldName].length)) {
                this.$emit('changed-valididy', false)
                return
            }

            this.loadingCustomer = true

            let customer = await Customer.getByDocument(value.replace(/\D/g,''))
                .catch(e => {
                    if (e.message == 'Network Error') {
                        this.networkError = true
                        return
                    }

                    let status = this.$lodash.get(e, 'response.status')
                    if (status === 404) {
                        this.customerNotFound = true
                        this.$emit('selected-customer', this.selectedCustomer)
                        return
                    }

                    this.errorHandler(e)
                })

            this.loadingCustomer = false

            if (!customer)
                return

            this.selectedCustomer = customer

            this.$emit('changed-valididy', true)
            this.$emit('selected-customer', this.selectedCustomer)
        },
    },
}
</script>

<style scoped lang="scss">
.limit-user-card {
    max-width: 400px;
}
</style>