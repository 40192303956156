import BaseModel from './BaseModel'

export default class SimpleCustomer extends BaseModel {

    defaults() {
        return {
            id                   : '', // não deve ser enviado nas requests, serve apenas para consulta
            email                : '',
            phone                : '',
            name                 : '',
            birthdate            : '',
            document             : '',
            address              : '',
            avatar_url           : '',
            current_password     : '',
            password             : '',
            password_confirmation: '',
            sex                  : '',
            level_infos          : null,
            firebaseResult       : null,
            login_type           : null,
            emailVerified        : null,
            use_cnpj             : false,
            require_password     : false,
            opt_in               : false,
            opt_in_marketing     : false,
            // Informações complexas
            levels_system        : {
                current: undefined,
                next   : undefined,
            },
            balance              : {
                amount_balance : 0,
                received_amount: 0,
                spent_value    : 0,
                value_balance  : 0,
            },
            // Informações auxiliares sobre níveis
            level_reset_pending  : false,
        }
    }
}