<template>
   <v-card class="elevation-0 background">
        <v-card-text class="px-0">
            <div class="section-title">
                {{ customer.name }}
            </div>

            <div class="mt-3 secondary_font--text">
                {{ customer.email }}
            </div>
        </v-card-text>
        <v-list v-if="!hidePoints" dense class="background">
            <v-row>
                <v-col cols="12" sm="6" md="12" :class="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
                    <v-list-item v-for="(item, index) in customerBalance" :key="index" class="px-3">
                        <v-list-item-content class="d-block">
                            <v-list-item-title class="list-item-title">{{ item.label }}</v-list-item-title>
                            <v-list-item-subtitle class="py-1 primary--text list-item-subtitle">{{ item.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="12" :class="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
                    <v-list-item v-for="(item, index) in customerTotalSpentReceived" :key="index" class="px-3">
                        <v-list-item-content class="d-block">
                            <v-list-item-title class="list-item-title">{{ item.label }}</v-list-item-title>
                            <v-list-item-subtitle class="py-1 primary--text list-item-subtitle">{{ item.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-list>
    </v-card>
</template>

<script>
// [FID-1154] Ajustar imports para usar os modelos no Shared
import Customer       from '@/models/Customer'
import SimpleCustomer from '../../models/SimpleCustomer'
import dynamicPoints  from '@/utils/filters/dynamicPoint'
import currency       from '@/utils/filters/currency'

export default {
    name: 'CustomerCard',
    props: {
        customer: {
            type: [ SimpleCustomer, Customer ],
            required: true,
        },
        hideCashback: {
            type: Boolean,
            default: false,
        },
        hidePoints: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        customerInfo() {
            if (this.hideCashback) {
                return [
                    { label: this.$t('components.cards.CustomerCard.balance.amount_balance.label'), value: dynamicPoints(this.customer.balance.amount_balance) },
                    { label: this.$t('components.cards.CustomerCard.balance.received_amount.label'), value: dynamicPoints(this.customer.balance.received_amount) },
                ]    
            }

            return [
                { label: this.$t('components.cards.CustomerCard.balance.amount_balance.label'), value: dynamicPoints(this.customer.balance.amount_balance) },
                { label: this.$t('components.cards.CustomerCard.balance.value_balance.label'),   value:  currency(this.customer.balance.value_balance) },
                { label: this.$t('components.cards.CustomerCard.balance.received_amount.label'), value: dynamicPoints(this.customer.balance.received_amount) },
                { label: this.$t('components.cards.CustomerCard.balance.spent_value.label'),     value: currency(this.customer.balance.spent_value) },
            ]
        },
        customerBalance() {
            const customerInfo = this.customerInfo
            // retornar o saldo em dinheiro e pontos ( primeira metade )
            return customerInfo.slice(0, Math.ceil(customerInfo.length / 2))
        },
        customerTotalSpentReceived() {
            const customerInfo = this.customerInfo
            // retornar total de pontos recebidos e o valor gasto em compras ( segunda metade )
            return customerInfo.slice(Math.ceil(customerInfo.length / 2))
        },
    },
}
</script>

<style lang="scss" scoped>
.list-item-title {
    font-size: 14px !important;
}

.list-item-subtitle {
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>