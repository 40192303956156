var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0 background" },
    [
      _c("v-card-text", { staticClass: "px-0" }, [
        _c("div", { staticClass: "section-title" }, [
          _vm._v(" " + _vm._s(_vm.customer.name) + " ")
        ]),
        _c("div", { staticClass: "mt-3 secondary_font--text" }, [
          _vm._v(" " + _vm._s(_vm.customer.email) + " ")
        ])
      ]),
      !_vm.hidePoints
        ? _c(
            "v-list",
            { staticClass: "background", attrs: { dense: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class:
                        _vm.$vuetify.breakpoint.mdAndUp ||
                        _vm.$vuetify.breakpoint.smAndDown
                          ? "pa-0"
                          : "",
                      attrs: { cols: "12", sm: "6", md: "12" }
                    },
                    _vm._l(_vm.customerBalance, function(item, index) {
                      return _c(
                        "v-list-item",
                        { key: index, staticClass: "px-3" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "d-block" },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item-title" },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass:
                                    "py-1 primary--text list-item-subtitle"
                                },
                                [_vm._v(_vm._s(item.value))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class:
                        _vm.$vuetify.breakpoint.mdAndUp ||
                        _vm.$vuetify.breakpoint.smAndDown
                          ? "pa-0"
                          : "",
                      attrs: { cols: "12", sm: "6", md: "12" }
                    },
                    _vm._l(_vm.customerTotalSpentReceived, function(
                      item,
                      index
                    ) {
                      return _c(
                        "v-list-item",
                        { key: index, staticClass: "px-3" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "d-block" },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "list-item-title" },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass:
                                    "py-1 primary--text list-item-subtitle"
                                },
                                [_vm._v(_vm._s(item.value))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }